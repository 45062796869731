import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME || 'Tutorie';



createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });
        Sentry.init({
            app,
            dsn: "https://aa71234f7750e31666c15d56665b037f@o4506651538489344.ingest.sentry.io/4506684760719360",
            integrations: [
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
                // new Sentry.Feedback({
                //     // Additional SDK configuration goes in here, for example:
                //     colorScheme: "light",
                //     showBranding: false,
                //     buttonLabel: "Send feedback",
                //     submitButtonLabel: "Send",
                //     messagePlaceholder: "What would you recommend us to improve? Did you find a bug or error? Is something unclear, or hard to follow?",
                //     showName: false,
                //     emailLabel: "Email (in case we need more information)",
                //     themeLight: {
                //         submitBackground: "rgba(51,120,198,1)",
                //         submitBackgroundHover: "rgba(35,92,166,1)",
                //         submitBorder: "rgba(35,92,166,1)",
                //         submitOutlineFocus: "rgba(35,92,166,1)",
                //     }
                // }),
            ],
            // Performance Monitoring
            tracesSampleRate: 0.5, //  Capture 50% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.1, // Sample 30% of the sessions
            replaysOnErrorSampleRate: 0.5, // Capture 100% of the sessions with an error
        });
        app.use(plugin).use(ZiggyVue).mount(el);
        return app;
    },
    progress: {
        color: '#4B5563',
    },
});
